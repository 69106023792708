@import "../css/index.css";

//fonts
@import "../../fonts/fontawesome-v5.8.1/css/style.css";
@import "../../fonts/gotham/css/style.css";
@import "../../fonts/muli/css/style.css";

//grid
@import "grid";

//slick
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body{
  font-family: Muli, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

h1, .h1{
  position: relative;
  color: #002c63;
  font-size: 30px;
  line-height: 120%;
  font-weight: 400;
  &.f-34{
    font-size: 34px;
    line-height: 36px;
  }
  margin-bottom: 45px;
  /*&:after{
    content: '';
    position: absolute;
    width: 30px;
    left: 0;
    bottom: -10px;
    background-color: #ffd862;
    height: 2px;
  }*/
  &.hide-border{
    &:after{
      display: none;
    }
  }
}

h2, .h2{
  color: #002c63;
  font-size: 40px;
  line-height: 46px;
}

.page-footer {
  background: #002C63;
  color: #fff;
  padding: 30px 0;
}

.page-footer-items {
  margin-bottom: 30px;
  @media (max-width: 575px) {
    text-align: center;
  }
}

.btn{
  border: 1px solid #000;
  text-transform: uppercase;
  padding: 9px 15px;
  line-height: inherit;
  max-width: 180px;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  &:disabled {
    background: #5f5f5f;
    color: #aaa;
    cursor: no-drop;
    border-color: #5f5f5f;
  }
}

.btn-full{
  max-width: 100%;
}

.btn-default{
  color: #000;
  transition: all .3s linear;
  &:hover{
    background-color: #000000;
    color: #ffffff;
  }
}

.btn-primary{
  background: #000;
  color: #fff;
  transition: all .3s linear;
  &:hover:not(:disabled){
    background-color: #ffffff;
    color: #000000;
  }
}

.btn-secondary{
  border-color: #3aa0ff;
  background: #3aa0ff;
  color: #fff;
  transition: all .3s linear;
  &:hover:not(:disabled){
    background-color: #ffffff;
    border-color: #3aa0ff;
    color: #3aa0ff;
  }
}

ul.pagination{
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
  list-style: none;
  align-items: center;
  margin: 30px 0;
  li{
    &.active{
      a{
        background-color: rgba(0, 176, 230, 0.50);
      }
    }
    a{
      width: 38px;
      height: 38px;
      &:hover{
        background-color: rgba(0, 176, 230, 0.25);
      }
      &.arrow{
        width: 32px;
        height: 32px;
        &:hover{
          background-color: #f5f5f5;
        }
      }
      color: #666e7a;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin: 0 3px;
    }
  }
}
