*,::after,::before {
  box-sizing: border-box;
  outline: none;
}

html {
  font-family: Gotham, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent
}

article,aside,figcaption,figure,footer,header,hgroup,main,nav,section {
  display: block
}

body {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: bold;
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}
