@font-face {
  font-family: 'Muli';
  src: url('../Muli-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../Muli-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../Muli-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
